<template>
  <section
    :class="[
      'benefit-detail',
      `benefit-${benefitInfo.type}`,
      benefitHeaderImage ? 'new-benefit-style' : '',
    ]"
    v-if="!loading"
  >
    <!-- <van-empty v-if="showEmpty" description="SKU不存在"></van-empty> -->
    <template>
      <div :class="{ 'benefit-header': true }">
        <div class="save-money" v-if="saveText">
          {{ saveText }}
        </div>
        <van-image
          lazy-load
          class="benefit-header-img"
          fit="cover"
          :src="benefitImage"
        />
        <div v-if="shareInfo" class="to-share" @click="openShareModal"></div>
      </div>
      <div
        :class="{
          'benefit-info': true,
          'benefit-info-margin': true,
        }"
      >
        <div class="benefit-info-title" v-if="benefitHeaderImage">
          {{ benefitInfo.title || benefitInfo.name }}
        </div>
        <div class="benefit-info-inner">
          <div class="benefit-name">
            <div class="benefit-icon" v-if="benefitIcon">
              <van-image lazy-load :src="benefitIcon" />
            </div>
            <div class="benefit-header-info">
              <div class="benefit-title">
                <span class="benefit-title-span">{{ getSecondTitle }}</span>
              </div>
              <div class="benefit-subtitle">
                {{ benefitInfo.subtitle }}
              </div>
            </div>
            <span v-if="amount > 0" class="benefit-amount"
              >剩{{ amount }}次</span
            >
          </div>
          <div class="benefit-sku">
            <transition name="height0">
              <div class="benefit-sku-input" v-if="showAccount">
                <div
                  class="benefit-input-label"
                  :style="`pointer-events: ${
                    selectSku.allowed_recharge_account_types.length > 1
                      ? 'initial'
                      : 'none'
                  }`"
                  @click="showSelectRechargePopup = true"
                >
                  充值{{ topUpText
                  }}<svg-icon
                    v-if="selectSku.allowed_recharge_account_types.length > 1"
                    class="benefit-input-label-arrow"
                    iconClass="arrow"
                  />
                  <span v-else>：</span>
                </div>
                <van-field
                  :disabled="disabled"
                  :placeholder="placeholder"
                  class="benefit-input"
                  :value="phone_account"
                  @input="changeAccount"
                  :error-message="error_message"
                  :type="input_type"
                />
                <div v-if="isp && isp.phone_number === account" class="isp">
                  {{ ispNameEnum[isp.isp] }}
                </div>
              </div>
            </transition>
            <div class="benefit-sku-list" v-if="skuInfo.length > 1">
              <van-row>
                <van-col
                  @click="skuChange(item, idx)"
                  :class="{
                    'sku-item': true,
                    'sku-flex': !!item.image,
                    active: item.id === selectSku.id,
                  }"
                  :span="12"
                  v-for="(item, idx) in skuInfo"
                  :key="item.id"
                  v-ans="{
                    event_id: 'benefit_detail_click_spu',
                    spu_id: benefitInfo.type,
                    spu_name: benefitInfo.name,
                    sku_id: selectSku.id,
                    sku_name: selectSku.item_name,
                    namespace,
                  }"
                >
                  <div
                    :class="{
                      'sku-item-inner': true,
                      'new-image': !!item.image,
                    }"
                  >
                    <van-image
                      lazy-load
                      class="sku-item-inner-image"
                      :src="item.image || getImage(item)"
                      fit="contain"
                    />
                    <div
                      v-if="item.image"
                      :class="{
                        'sku-item-name': true,
                      }"
                    >
                      {{ item.platform_product_name }}
                    </div>
                    <div v-if="item.status === 0" class="out-stock">无货</div>
                  </div>
                </van-col>
              </van-row>
            </div>
          </div>
          <div class="benefit-btn-wrap">
            <van-button
              v-ans="{
                event_id: 'benefit_detail_click_button1',
                spu_id: benefitInfo.type,
                spu_name: benefitInfo.name,
                namespace,
                source,
                account: phone_account,
              }"
              :disabled="btnDisabled"
              @click="submit"
              class="benefit-btn"
              block
              round
              >{{ getBtnText }}</van-button
            >
            <van-button
              v-if="benefitInfo.type === 'meituancashback'"
              @click="$router.push('/cashBack')"
              block
              type="default"
              round
              >我的返现</van-button
            >
            <van-button
              v-if="showAppointBtn"
              v-ans="{
                event_id: 'benefit_detail_click_button3',
                spu_id: benefitInfo.type,
                spu_name: benefitInfo.name,
                namespace,
              }"
              :disabled="appointBtnDisabled"
              @click="toAppoint"
              class="akgb-btn"
              block
              type="default"
              round
              >立即使用</van-button
            >
            <van-button
              v-if="benefitInfo.coupon_banner_url"
              @click="toCoupon"
              class="akgb-btn"
              block
              type="default"
              round
              >查看领取记录</van-button
            >
          </div>
        </div>
      </div>
      <div
        class="benefit-desc"
        v-if="benefitInfo.benefit_instruction || benefitInfo.desc_img_url"
      >
        <div class="benefit-desc-label">权益介绍</div>
        <div class="instruction">
          <div
            v-if="benefitInfo.benefit_instruction"
            :class="{ 'rich-text': true, 'rich-text-more': more }"
            :key="selectSku.item_code"
            v-replace.day="benefitInfo.coupon_days"
            v-html="benefitInfo.benefit_instruction"
          ></div>
          <van-image lazy-load v-else :src="benefitInfo.desc_img_url" />
        </div>
      </div>
      <div class="benefit-desc">
        <div>
          <div class="benefit-desc-label">使用指南</div>
          <div class="instruction">
            <van-image
              lazy-load
              v-if="benefitInfo.manual_img_url"
              :src="benefitInfo.manual_img_url"
            />
            <div v-else :class="{ 'rich-text': true, 'rich-text-more': more }">
              <van-image
                v-if="selectSku.instruction_img_url"
                :src="selectSku.instruction_img_url"
              />
              <div
                @click="handleUseGuide"
                v-if="selectSku.instruction || selectSku.spu_instruction"
                :key="selectSku.item_code"
                v-replace.day="benefitInfo.coupon_days"
                v-html="selectSku.instruction"
                class="instruction-html"
              ></div>
            </div>
            <a
              v-if="benefitInfo.manual_extra_url"
              class="airport-list"
              :href="benefitInfo.manual_extra_url"
              className="airport-list"
              >{{ benefitInfo.manual_extra_link_name }}</a
            >
          </div>
        </div>
      </div>

      <div
        v-if="!$getConfigValue('HIDE_BENEFIT_ORDER_ENTRY')"
        class="to-order-btn"
        v-ans="{
          event_id: 'benefit_detail_click_button2',
          spu_id: benefitInfo.id,
          spu_name: benefitInfo.name,
          namespace,
          source,
        }"
        @click="$router.push('/order')"
      ></div>
      <mileageModal
        :value.sync="showMileageModal"
        @refresh="refresh"
        :selectSku="selectSku"
      />
      <atour v-if="showAtour" :refresh="refresh" :showAtour.sync="showAtour" />
      <airporttransfer
        :sku="selectSku"
        v-if="showAirport"
        :refresh="refresh"
        :showAirport.sync="showAirport"
      />
      <selectPopup
        title="选择账号充值类型"
        :value="selectSku.recharge_account_number"
        @update:value="changeRechargeAccountNumber"
        :options="selectSku.allowed_recharge_account_types"
        :optionFieldMap="{
          label: 'account_type_name',
          value: 'account_type_number',
        }"
        v-model="showSelectRechargePopup"
      />
    </template>
  </section>
</template>

<script>
import {
  activateBenefit,
  queryPhoneIsp,
  querySkuStock,
  queryTotalSavMoney,
  shareBenefit,
  updateUserPhone,
  getCardInfo,
} from "@/apis/home";
import { mapActions, mapState } from "vuex";
import atour from "./atour";
import airporttransfer from "./airporttransfer";

import {
  getBenefitInfo,
  getSkuByBenefitType,
  getXinshenPoint,
  checkPhone,
  canAppoint,
  cleanTeethCanAppoint,
  getOrder,
  getNamespaceBenefitImage,
} from "@/apis";
import qs from "qs";
import copy from "copy-to-clipboard";
import mileageModal from "./mileageModal";
import throttle from "lodash.throttle";
import dayjs from "dayjs";
import ans from "@/utils/ans";
import yioil from "./yioil";
import pickBy from "lodash.pickby";
import { viproom_list } from "@/config";
import {
  queryMobileOperator,
  queryH5JumpUrl,
  queryUserRechargeAccount,
} from "@/apis/product";
import { jsonSafeParse, replaceDay, storage } from "@/utils";
import { verfyResult } from "@/apis/identityVerfy";
import selectPopup from "@/components/selectPopup";
import { isValidPhone } from "@/helper/validate";

const hide_after_use = ["atour", "hotel", "ehotel", "buffet"];

export default {
  components: {
    mileageModal,
    airporttransfer,
    atour,
    selectPopup,
  },
  mixins: [yioil],
  data() {
    return {
      showAtour: false,
      showAirport: false,
      loading: true,
      benefitInfo: {},
      saveText: "",
      isp: null,
      skuInfo: [],
      skus: [],
      selectSku: {
        allowed_recharge_account_types: [],
      },
      shareInfo: null,
      more: false,
      showMileageModal: false,
      faceValueStatus: [],
      ispNameEnum: {
        中国移动: "中国移动",
        中国联通: "中国联通",
        中国电信: "中国电信",
      },
      abnormal_account: false,
      phone_account: "",
      error_message: "",
      akgbBtnDisabled: false,
      showAkgbUse: false,
      images: {},
      showSelectRechargePopup: false,
      rechargeAccounts: [],
    };
  },
  computed: {
    ...mapState([
      "namespace",
      "source",
      "userInfo",
      "isMiniProgram",
      "isWebchat",
      "isAndroid",
      "isiOS",
      "isShengbeiApp",
      "namespaceInfo",
      "benefit_source",
    ]),
    showAccount() {
      return this.selectSku.allowed_recharge_account_types?.length > 0;
    },
    account: {
      get() {
        return this.phone_account.replace(/ /g, "");
      },
      set() {},
    },
    input_type() {
      return this.selectSku.recharge_account_number === 1 ? "tel" : "text";
    },
    isFreeBenefit() {
      return this.selectSku.item_price === 0;
    },
    // showEmpty() {
    //   if (/^airporttransfer|atour$/.test(this.benefitInfo.type)) {
    //     return false;
    //   }
    //   return this.skuInfo.length === 0;
    // },
    amount() {
      // 亚朵不显示剩余次数
      if (this.$route.query.benefit_type === "atour") {
        return 0;
      }
      // 免费权益不显示点数
      if (this.selectSku.top_up_type === 5) {
        return 0;
      }
      return (
        this.benefitInfo.amount_available + this.benefitInfo.amount_reserved
      );
    },
    // 判断商品下架
    productOff() {
      // 判读是否话费,根据话费isp判断各个渠道是否下架
      // 如果查询到isp，则根据配置判断，没有的话就判断移动|联通|电信是否配置了下架
      const attributes = this.selectSku.attributes || {};
      if (
        attributes.is_phone === "1" &&
        this.isp?.phone_number === this.account
      ) {
        return this.faceValueStatus[this.selectSku.face_value] === 0;
      }
      // 库存状态上下架
      return this.selectSku.status === 0;
    },
    // 权益icon
    benefitIcon() {
      return this.images.titleIcon?.[0].image_url;
    },

    // 权益头图
    benefitHeaderImage() {
      return this.images.header?.[0].image_url;
    },

    //获取顶部头图链接
    benefitImage() {
      // 先取套餐权益配比地址，没有的话就取spu 地址 再没有的话就从oss取
      return (
        this.benefitHeaderImage ||
        // this.skus[0]?.product_img ||
        `${this.$cdn}/static/${this.namespace}/${this.$route.query.benefit_type}-bg2.png`
      );
    },
    placeholder() {
      return "请输入" + this.topUpText;
    },
    topUpText() {
      return (
        this.selectSku.allowed_recharge_account_types?.find(
          (item) =>
            item.account_type_number === this.selectSku.recharge_account_number
        )?.account_type_name || "充值账号"
      );
    },
    // 按钮文案逻辑
    getBtnText() {
      const { benefit_type } = this.$route.query;
      const temp = jsonSafeParse(this.$getConfigValue("BENEFIT_SPECIAL_TEXT"));
      if (this.userInfo.is_member) {
        for (let k in temp) {
          if (this.benefitInfo.type?.startsWith(k) && temp[k]) {
            return temp[k];
          }
        }
        if (
          this.benefitInfo.amount_available <= 0 &&
          !hide_after_use.includes(benefit_type) &&
          this.benefitInfo.amount_reserved <= 0
        ) {
          return "本权益已领完";
        }
        // 月花卡显示文案  暂时写死，后期改成配置读取
        if (
          this.benefitInfo.amount_available <= 0 &&
          this.benefitInfo.amount_reserved > 0 &&
          !hide_after_use.includes(benefit_type)
        ) {
          //text_notify为本期权益使用完的字段
          if (this.benefitInfo.text_notify) {
            return this.benefitInfo.text_notify;
          }
          return "本期权益已使用，请下月再来";
        }

        if (this.productOff) {
          return "无货，请选择其他面额";
        }
        if (this.selectSku.item_price > 0) {
          return `立即购买（${this.selectSku.item_price}元）`;
        }
      }
      return "立即领取";
    },
    user_phone() {
      if (this.selectSku.recharge_account_number !== 1) {
        return "";
      }
      return (
        this.rechargeAccounts.find(
          (item) =>
            item.account_type_number === this.selectSku.recharge_account_number
        )?.account || ""
      );
    },
    // 账号充值类型为手机号的sku，自动填入权益手机号
    disabled() {
      // 未启用充值账号绑定功能，不禁用
      if (!this.namespaceInfo.enable_recharge_account_limit) {
        return false;
      }
      return !!this.rechargeAccounts.find(
        (item) =>
          item.account_type_number === this.selectSku.recharge_account_number
      );
    },

    btnDisabled() {
      // 非会员，让点击按钮
      // 跳转h5的链接，不限制
      if (!this.userInfo.is_member || this.selectSku.top_up_type === 5) {
        return false;
      }
      // 禁用keep会员按钮
      if (
        (this.benefitInfo.type || "").includes("keepvip") &&
        this.namespace === "keep"
      ) {
        return true;
      }

      const { benefit_type } = this.$route.query;
      // 没有点数，禁用按钮
      if (
        (this.benefitInfo.amount_available <= 0 &&
          !hide_after_use.includes(benefit_type)) ||
        this.benefitInfo.amount_reserved === undefined
      ) {
        return true;
      }
      return this.productOff;
    },
    showAppointBtn() {
      const { benefit_type } = this.$route.query;
      return ["akgb", "hljyk"].includes(benefit_type);
    },
    getSecondTitle() {
      if (!this.userInfo.is_member) {
        return "您还不是会员";
      }
      if (!this.benefitIcon) {
        return this.benefitInfo.title || this.benefitInfo.name;
      }
      if (this.benefitInfo.brief_subtitle) {
        return this.benefitInfo.brief_subtitle;
      }
      if (
        this.benefitInfo.amount_available <= 0 &&
        this.benefitInfo.amount_reserved <= 0
      ) {
        return "本权益已用完";
      }
      const unit = this.benefitInfo.use_period_frequency
        ? `本${this.benefitInfo.use_period_frequency}`
        : "当前";
      return [
        `${unit}可用${this.benefitInfo.amount_available}次`,
        this.benefitInfo.next_use_wait_days !== null &&
          `距下${this.benefitInfo.use_period_frequency || "次"}使用还有${
            this.benefitInfo.next_use_wait_days
          }天`,
      ]
        .filter(Boolean)
        .join("，");
    },
  },

  async mounted() {
    const { benefit_type, detail_type } = this.$route.query;
    const temp = {
      starbucks: "sbux",
      mangguo: "mgtv",
      aiqiyi: "iqiyi",
    };
    const skuType = temp[detail_type] || detail_type;
    const toast = this.$loading();
    try {
      await this.fetchUserInfo();
      const [sku, benefit, share, saveText, imageRes, rechargeAccountRes] =
        await Promise.all([
          getSkuByBenefitType({
            benefit_type,
            source: this.source,
            namespace: this.namespace,
          }),
          this.fetchBenefitInfo({ benefit_type }),
          this.getShareInfo(),
          this.getTotalMoney(),
          getNamespaceBenefitImage({
            namespace_en: this.namespace,
            product_code: benefit_type,
          }),
          queryUserRechargeAccount(),
        ]);
      this.rechargeAccounts = rechargeAccountRes.data;
      this.images = imageRes.data;
      // 过滤掉话费权益里面包含京东eka的sku
      this.skus = sku.data;
      // todo mock数据
      sku.data.forEach((item, idx) => {
        if (
          item.allowed_recharge_account_types &&
          item.allowed_recharge_account_types.length > 0
        ) {
          item.recharge_account_number =
            item.allowed_recharge_account_types[0].account_type_number;
        }
      });
      if (sku.data.some((item) => item.attributes?.is_phone === "1")) {
        const filterItemCodes =
          this.$getConfigValue("JD_ECARD_ITEMCODE").split("|");
        this.skuInfo = sku.data.filter(
          (item) => !filterItemCodes.includes(item.item_code)
        );
      } else {
        this.skuInfo = sku.data;
        //埋堆堆没有季卡则不显示优酷sku
        if (this.namespace.startsWith("mdd")) {
          const mdd_card_info = await getCardInfo();
          if (!mdd_card_info.data) {
            this.skuInfo = sku.data.filter(
              (r) => r.item_code !== "combo-sy-youku"
            );
          }
        }
      }

      this.saveText = saveText;
      if (this.skuInfo.length) {
        this.selectSku =
          this.skuInfo.find((r) => r.item_code.includes(skuType)) ||
          this.skuInfo[0];
      }
      // 酒店不显示sku
      if (
        benefit_type === "hotel" ||
        benefit_type === "ehotel" ||
        benefit_type === "buffet"
      ) {
        this.skuInfo = [{}];
      }
      if (benefit_type === "akgb") {
        //查询该用户是否可预约
        const res = await canAppoint();
        this.appointBtnDisabled = !res.data;
      }
      if (benefit_type === "hljyk") {
        //查询该用户是否可预约洁牙
        const res = await cleanTeethCanAppoint({
          user_id: this.userInfo.id,
          namespace_name: this.namespace,
        });
        //查询全部洁牙订单，全部为已退款则不能预约
        const res2 = await getOrder({
          source: "网络支付",
          order_type: "hljyk",
        });
        this.cleanTeethOrder = res.data;
        if (res.data) {
          const hljyk =
            res2.data.res_list.filter(
              (r) => r.order_number === res.data.order_number
            )[0] || {};
          if (hljyk.status === "success") {
            this.appointBtnDisabled = false;
          } else {
            this.appointBtnDisabled = true;
          }
        } else {
          this.appointBtnDisabled = true;
        }
      }
      this.benefitInfo = benefit.data;
      ans.pageView(this.$route.meta.page, {
        namespace: this.namespace,
        spu_id: this.benefitInfo.id,
        spu_name: this.benefitInfo.name,
        source: this.source,
        benefit_type: this.benefitInfo.type,
      });
    } finally {
      toast.clear();
      this.loading = false;
    }
  },
  methods: {
    ...mapActions(["fetchUserInfo"]),
    async getTotalMoney() {
      if (!this.$getConfigValue("SHOW_SAVING_MONEY")) {
        return "";
      }
      try {
        const res = await queryTotalSavMoney();
        const { total, unit, expected_saving } = res.data;
        return total
          ? `生活特权已为您累计节省${total}${unit}`
          : `生活特权预计可为您节省${expected_saving}${unit}`;
      } catch (e) {
        return "";
      }
    },

    handleUseGuide(event) {
      const { special_info, product_name } = this.selectSku;
      if (product_name === "瑞鹏宠物") {
        const target = event.target;
        if (target.localName === "a") {
          // 获取到a标签上的链接
          const url = target.getAttribute("href");
          const template_id = JSON.parse(special_info)?.template_id;
          const handleUrl = `${url}?template_id=${template_id}`;
          target.href = handleUrl;
        }
      }
    },
    async getPhoneIsp() {
      // 缓存下查询结果
      if (this.isp?.phone_number === this.account) {
        return;
      }
      if (this.selectSku.attributes?.is_phone === "1") {
        // 查询手机归属地
        const isp = await queryPhoneIsp({ phone_number: this.account });
        // 如果是电信手机，并且充话费，显示所有配置的权益
        if (
          isp.data?.isp === "电信" &&
          this.skuInfo.some((item) => item.attributes?.is_phone === "1") &&
          this.skuInfo.length !== this.skus.length
        ) {
          this.skuInfo = this.skus;
          if (this.skuInfo.length) {
            this.selectSku = this.skuInfo[0];
          }
        }
        this.isp = isp.data;
      }
    },
    changeAccount(e) {
      if (this.selectSku.recharge_account_number === 1) {
        let s = e.replace(/(^\d{3}|\d{4}\B)/g, "$1 ").replace("  ", " ");
        this.phone_account = s;
        if (this.account.length && this.account.length !== 11) {
          this.error_message = "请输入正确的手机号";
        } else {
          this.error_message = "";
        }
        return;
      }
      this.phone_account = e;
    },
    // sku 图片
    getImage(item) {
      // 先取权益规则配比里面的图片  没有的话就取oss图片
      return (
        item.product_img_url ||
        `https://cdn.xiangshuheika.com/static/${this.namespace}/benefit/${item.item_code}.png`
      );
    },
    async refresh() {
      const { benefit_type } = this.$route.query;
      const res = await getBenefitInfo({ benefit_type });
      this.benefitInfo = res.data;
    },
    async fetchBenefitInfo(params) {
      try {
        const res = await getBenefitInfo(params);
        return res;
      } catch (e) {
        return { data: {} };
      }
    },
    async getShareInfo() {
      //获取活动信息
      const { benefit_type } = this.$route.query;
      const SY_BENEFIT_SHARE_INFO = this.$getConfigValue(
        "SY_BENEFIT_SHARE_INFO"
      );
      if (SY_BENEFIT_SHARE_INFO) {
        const info = jsonSafeParse(SY_BENEFIT_SHARE_INFO);
        if (info[benefit_type]) {
          this.shareInfo = info[benefit_type];
        }
      }
    },
    openShareModal() {
      if (this.benefitInfo.amount_available <= 0) {
        this.$toast("当前可用次数不足");
        return;
      }
      this.$alert({
        title: "分享前确认",
        confirmButtonText: "同意并分享",
        message:
          "您的本月可用次数将分享赠送给好友，您本月将不可使用该权益。是否继续分享",
      }).then(async (ok) => {
        const loading = this.$loading();
        try {
          const { benefit_type } = this.$route.query;
          const res = await shareBenefit({
            namespace: "fs",
            benefit_type: benefit_type,
            benefit_source: "会员权益",
          });
          await this.refresh();
          // 生成兑换链接
          const link = `${location.origin}${
            location.pathname
          }#/shareBenefit?${qs.stringify(
            {
              source: "fs",
              code: res.data.cashing_code,
              benefit_type,
              title: encodeURIComponent(this.benefitInfo.title),
              subtitle: encodeURIComponent(this.benefitInfo.subtitle),
              expired: dayjs(res.data.t_expiration).valueOf(),
              from: this.namespace,
            },
            { encode: false }
          )}`;
          // 获取分享链接
          if (this.isShengbeiApp) {
            const shareInfo = JSON.stringify({
              scene: "",
              title: this.shareInfo.title,
              subtitle: this.shareInfo.subtitle,
              html5: link,
              iconURL: "https://cdn.xiangshuheika.com/static/sy/share.png",
              imageData: "",
              isInvite: false,
              shareto: "wxfriend",
            });
            if (this.isAndroid) {
              SMYJSInterface.appShare(shareInfo);
            } else if (this.isiOS) {
              window.webkit.messageHandlers.SMYJSInterface.postMessage({
                method: "appShare",
                param: shareInfo,
              });
            }
          } else {
            copy(link);
          }
        } finally {
          loading.clear();
        }
      });
    },
    changeRechargeAccountNumber(type) {
      this.selectSku = {
        ...this.selectSku,
        recharge_account_number: type,
      };
    },
    isYangmaodang() {
      const tag = this.userInfo.user_tags || [];
      return Object.keys(tag).some((item) => item.includes("yang_mao_dang"));
    },
    submit: throttle(
      async function () {
        if (this.namespace == "cr") {
          if (!this.isYangmaodang()) {
            this.$alert({
              message: `<div style="margin-bottom:10px;font-size:16px">尊敬的通享卡用户</div>感谢您一路以来的支持与厚爱！因华润通经营策略调整，通享卡现已暂停发售，通享卡会员权益将于2024年09月30日0时起暂停服务，届时还在有效期内的会员用户可在10月31日前申请全额退款服务。退款申请联系客服：400-150-9669；登记审核后退款金额将于15个工作日内到账。为您带来的不便，我们深表歉意。<div style="text-align:right">--- 2024年9月6日</div>`,
            });
            return;
          }
          const res = await verfyResult();
          const status = res.data.verify_status;
          if (status === "refused") {
            this.$confirm({
              message:
                "经检测，您的通享卡使用行为存在风险，为保障您的合法权益，暂时无法使用通享卡会员权益服务",
              title: "温馨提示",
              showCancelButton: false,
              confirmButtonText: "好的",
            });
            return;
          }
          if (status === "failed" || status === "no_verify") {
            this.$confirm({
              message:
                "经检测，您的通享卡使用行为存在风险，为保障您的合法权益，请先完成实名认证与活体验证",
              title: "风险提示",
              showCancelButton: false,
              confirmButtonText: "好的",
            }).then((res) => {
              this.$router.push({
                path: "/centification",
                query: {
                  return_url: encodeURIComponent(location.href),
                  //isFaceVerfy: true,
                },
              });
            });

            return;
          }
          if (status === "confirming") {
            this.$toast("您的实名认证信息正在核验中，请稍后重试");
            return;
          }
        }
        document.body.scrollIntoView();
        if (!this.userInfo.is_member) {
          if (this.$getConfigValue("UN_INDEX")) {
            this.$alert({
              title: "温馨提示",
              message: "您还不是会员，开通会员即可享受特惠权益。",
              cancelButtonText: "我再想想",
              confirmButtonText: "去开通",
            }).then(() => {
              this.$jumpUrl(this.$getConfigValue("UN_INDEX"));
            });
          } else {
            this.$toast("您还不是会员，开通会员即可享受特惠权益。");
          }
          return;
        }
        const MALICIOUS_USERS = this.$getConfigValue("MALICIOUS_USERS") + "";
        if (MALICIOUS_USERS) {
          const users = MALICIOUS_USERS.split(",").filter(Boolean);
          if (users.includes(this.userInfo.id + "")) {
            this.$alert({
              title: "温馨提示",
              message: this.$getConfigValue("MALICIOUS_USER_ALERT_TEXT"),
            });
            return;
          }
        }
        if (
          this.benefitInfo.type === "hotel" ||
          this.benefitInfo.type === "ehotel"
        ) {
          this.$router.push(
            `/hotel/list?benefitType=${this.benefitInfo.type}&product_code=${this.selectSku.product_code}`
          );
          return;
        }
        if (this.benefitInfo.type === "buffet") {
          this.$router.push(
            `/buffet/list/?benefitType=${this.benefitInfo.type}`
          );
          return;
        }
        // 判断是否为团油或者易加油
        // 对加油链接进行处理
        if (this.benefitInfo.type === "yioil") {
          this.checkYioil();
          return;
        }
        if (this.benefitInfo.type === "coco_benefits07") {
          if (this.selectSku.activation_tips) {
            copy(this.selectSku.activation_tips);
            this.$alert({ message: "已复制管家微信号" });
          } else {
            this.$toast("未配置微信号");
          }
          return;
        }
        // 校验点数
        if (this.showAccount && !this.account) {
          this.$toast("请输入充值账号");
          return;
        }

        // 校验输入的账号格式
        const rechargeConfig =
          this.selectSku.allowed_recharge_account_types.find(
            (item) =>
              item.account_type_number ===
              this.selectSku.recharge_account_number
          ) || {};
        if (rechargeConfig?.account_regex_rule) {
          if (
            !new RegExp(rechargeConfig?.account_regex_rule).test(this.account)
          ) {
            this.$toast(
              `您输入的${rechargeConfig.account_type_name}格式不正确，请核实后重新输入`
            );
            return;
          }
        }
        if (this.selectSku.need_real_name) {
          if (!this.userInfo.is_verified) {
            this.$alert({
              title: "温馨提示",
              message: "您还没有实名认证，请先认证",
              confirmButtonText: "去认证",
            }).then(() => {
              this.$router.push("/centification");
            });
            return;
          }
        }

        // 跳转到h5
        if (this.selectSku.top_up_type === 5) {
          const url =
            this.selectSku.activation_page ||
            this.selectSku.partner_jump_url ||
            "";
          if (!url) {
            const url = await queryH5JumpUrl({
              user_id: this.userInfo.id,
              generate_h5_code: this.selectSku.generate_h5,
              spu_id: this.selectSku.generate_h5_spu_id,
              phone_number: this.userInfo.user_phone,
            });
            location.href = url.data;
            return;
          }
          if (url.startsWith("/")) {
            this.$router.push(url);
          } else {
            location.href = url;
          }
          return;
        }

        // 判断是否亚朵
        if (this.benefitInfo.type === "atour") {
          this.showAtour = true;
          return;
        }
        // 判断是否接送机
        if (this.benefitInfo.type === "airporttransfer") {
          this.showAirport = true;
          return;
        }

        // 查询库存
        try {
          const stock = await querySkuStock({
            item_code: this.selectSku.item_code,
            user_id: this.userInfo.id,
            hideMsg: true,
          });
          if (
            stock.data.daily_stocks <= 0 ||
            stock.data.partner_stocks <= 0 ||
            stock.data.saleable_stocks <= 0 ||
            stock.data.user_daily_stocks <= 0 ||
            !stock.data.status
          ) {
            this.$toast("该商品暂时无货，请明天再来或咨询客服");
            return;
          }
        } catch (e) {}
        // 判断有没有可用的点数
        if (
          this.benefitInfo.amount_available <= 0 &&
          this.benefitInfo.amount_reserved
        ) {
          this.$alert({
            message: "您本月已用过此权益，请下个月再来",
            confirmButtonText: "好的",
          });
          return;
        }

        // 可用次数
        if (
          this.benefitInfo.amount_available <= 0 &&
          !this.benefitInfo.amount_reserved
        ) {
          this.$alert({
            message: "剩余可用次数不足，可前往订单查看已获取的权益或券码。",
            confirmButtonText: "去查看",
          }).then(() => {
            this.$router.push("/order");
          });
          return;
        }

        // 星盛兑换里程
        if (this.benefitInfo.type === "coco_benefits05") {
          // 查询积分
          const loading = this.$loading();
          try {
            const res = await getXinshenPoint();
            if (res.data.point < 2500) {
              this.$toast("星河积分不足");
              return;
            }
            this.showMileageModal = true;
          } finally {
            loading.clear();
          }
          return;
        }
        //需要购买的权益判断是否是非法权益
        if (
          this.selectSku.top_up_type === 1 ||
          this.selectSku.top_up_type === 3
        ) {
          const params = pickBy(
            {
              benefit_type: this.benefitInfo.type,
              benefit_source: this.benefit_source || "会员权益",
              is_zero_pay: true,
              order_name: this.selectSku.item_name,
              item_code: this.selectSku.item_code,
              top_up_type: this.selectSku.top_up_type,
              detail_type: viproom_list.includes(this.benefitInfo.type)
                ? undefined
                : this.selectSku.item_name,
              activation_platform: this.selectSku.activation_platform,
              phone_number: this.account,
              source: this.source,
              pre_activate: true,
            },
            (v) => v !== undefined
          );
          await activateBenefit(params);
        }
        //判断是否是异常账号
        if (this.abnormal_account) {
          await this.$alert({
            title: "温馨提示",
            message: `系统检测到手机号：<b>${this.phone_account}</b>可能为空号，是否继续？`,
            cancelButtonText: "我再想想",
            confirmButtonText: "确认号码",
          });
        }
        // if (this.selectSku.recharge_account_number === 2) {
        //   await this.$alert({
        //     title: "温馨提示",
        //     message: `确定充值到QQ号：<b>${this.phone_account}</b>吗？<br/>QQ号与手机号不互通，充值手机号权益无法到账，请确定是否继续？`,
        //     cancelButtonText: "我再想想",
        //     confirmButtonText: "确认号码",
        //   });
        // }
        if (this.selectSku.recharge_account_number === 6) {
          await this.$alert({
            title: "温馨提示",
            message: `确定充值到平台账号：<b>${this.phone_account}</b>吗？`,
            cancelButtonText: "我再想想",
            confirmButtonText: "确认号码",
          });
        }
        // 手机号充值增加额外提醒
        // 如果用户首次使用手机号充值，怎弹窗提醒用户，并且异步校验手机号是否能够充值
        if (
          !this.disabled &&
          this.showAccount &&
          this.namespaceInfo.enable_recharge_account_limit
        ) {
          if (this.selectSku.recharge_account_number !== 6) {
            await this.$alert({
              title: "温馨提示",
              confirmButtonText: "确认号码",
              message: `<div>确定充值到${rechargeConfig.account_type_name}：<b>${this.phone_account}</b>吗？<br />此${rechargeConfig.account_type_name}将会用于所有${rechargeConfig.account_type_name}充值的权益，填写后不可修改，请确认是否继续使用？</div>`,
            });
          }

          // 针对手机号充值类型，额外校验
          // 绑定手机号
          if (this.selectSku.recharge_account_number === 1) {
            const loading = this.$loading();
            try {
              await updateUserPhone({
                user_phone: this.account,
                hideMsg: true,
                is_save: "F",
              });
            } catch (e) {
              this.$toast(
                "手机号已被其它账号绑定，不可使用，如有疑问请联系客服"
              );
              return;
            } finally {
              loading.clear();
            }
          }
        }
        // 兑换码有激活提示
        if (
          this.selectSku.top_up_type === 3 ||
          this.selectSku.top_up_type === 4
        ) {
          const { activation_tips } = this.selectSku;
          await this.$alert({
            title: "温馨提示",
            message: replaceDay(activation_tips, this.benefitInfo.coupon_days),
            confirmButtonText: this.isFreeBenefit ? "继续领取" : "继续购买",
          });
        }
        this.toPay();
      },
      1500,
      { trailing: false }
    ),

    async activateBenefitHandle() {
      const params = pickBy(
        {
          benefit_type: this.benefitInfo.type,
          benefit_source: this.benefit_source || "会员权益",
          is_zero_pay: true,
          order_name: this.selectSku.item_name,
          item_code: this.selectSku.item_code,
          top_up_type: this.selectSku.top_up_type,
          detail_type: viproom_list.includes(this.benefitInfo.type)
            ? undefined
            : this.selectSku.item_name,
          activation_platform: this.selectSku.activation_platform,
          phone_number: this.account,
          source: this.source,
          recharge_account_type: this.selectSku.recharge_account_number || 0,
        },
        (v) => v !== undefined
      );
      // 华润通停车场激活参数
      if (params.benefit_type === "freeparking") {
        const CR_PARAMS = storage.getItem("CR_PARAMS");
        if (CR_PARAMS) {
          try {
            const obj = JSON.parse(CR_PARAMS);
            params.third_party_params = JSON.stringify({
              token: obj.token,
              device_param: obj.device,
            });
          } catch (e) {}
        }
      }
      const toast = this.$loading();
      try {
        const res = await activateBenefit(params);
        // 更新点数
        const [benefit, rechargeAccountRes] = await Promise.all([
          getBenefitInfo({
            benefit_type: this.benefitInfo.type,
          }),
          queryUserRechargeAccount(),
          this.fetchUserInfo(),
        ]);

        this.rechargeAccounts = rechargeAccountRes.data;
        this.benefitInfo = benefit.data;
        this.$alert({
          message: this.account
            ? `您已成功领取权益，充值至对应账号中，可在我的订单中查看结果`
            : "您已成功领取会员兑换码，<br/>请在我的订单中查看并激活使用",
          confirmButtonText: "去查看",
        }).then(() => {
          this.$router.push(
            `/orderDetail?order_number=${res.data.order_number}&from=activate`
          );
        });
      } finally {
        toast.clear();
      }
    },
    async toPay() {
      // top_up_type:
      // (1, "直冲-购买"),
      // (2, "直冲-免费领取"),
      // (3, "兑换码-购买"),
      // (4, "兑换码-免费领取"),
      // (5, "跳转H5")

      // 免费领取逻辑
      if (this.isFreeBenefit) {
        // 直冲虚拟权益激活提示
        if (this.account) {
          this.$alert({
            title: "温馨提示",
            message: `<div style="white-space:initial">
            <p style="font-size:16px">${this.selectSku.item_name},<br/>即将充值到您的账户，请确认</p>
            <p style="color:#999;padding-top:10px">虚拟商品，充值之后概不退换</p></div>`,
            confirmButtonText: "继续领取",
          }).then(() => {
            this.activateBenefitHandle();
          });
        } else {
          this.activateBenefitHandle();
        }
        return;
      }

      // 跳转收银台
      const params = pickBy(
        {
          source: this.source,
          item_code: this.selectSku.item_code,
          price: this.selectSku.item_price,
          item_name: decodeURIComponent(this.selectSku.item_name),
          sku_name: decodeURIComponent(this.selectSku.item_name),
          type: this.benefitInfo.type,
          recharge_account_type: this.selectSku.recharge_account_number || 0,
          phone_number: this.account,
          detail_type: viproom_list.includes(this.benefitInfo.type)
            ? undefined
            : decodeURIComponent(this.selectSku.item_name),
        },
        (v) => v !== undefined
      );
      this.$router.push(`/pay?${qs.stringify(params, { encode: false })}`);
    },
    skuChange(item, idx) {
      if (item.item_code === this.selectSku.item_code) return;
      this.selectSku = item;
      this.abnormal_account = false;
    },
    async checkPhone() {
      //检测手机号是否为空号
      const res = await checkPhone({
        phone: this.account,
      });
      const { checked, status } = res.data;
      this.abnormal_account = checked && ["空号", "沉默号"].includes(status);
    },

    async queryPhoneFaceStatus(isp) {
      const face_values = this.skuInfo
        .filter((r) => r.attributes?.is_phone === "1")
        .map((item) => item.face_value);
      if (!face_values.length) {
        return;
      }
      const str = [...new Set(face_values)].join("|");
      const ispEnum = {
        移动: "CM",
        中国移动: "CM",
        联通: "CU",
        中国联通: "CU",
        电信: "CT",
        中国电信: "CT",
      };
      const res = await queryMobileOperator({
        isp: ispEnum[isp.isp] || isp.isp,
        face_values: str,
        namespace_name: this.namespace,
      });
      this.faceValueStatus = res.data;
    },
    toAppoint() {
      const { benefit_type } = this.$route.query;
      if (benefit_type === "akgb") {
        this.$router.push("/akBook");
      }
      if (benefit_type === "hljyk") {
        this.$router.push(
          `/cleanTeeth?order_number=${this.cleanTeethOrder.order_number}`
        );
      }
    },
    toCoupon() {
      this.$jumpUrl(this.benefitInfo.coupon_banner_url, "switchTab");
    },
  },
  watch: {
    account: {
      // 判断手机充值
      handler: function (val) {
        if (this.selectSku.recharge_account_number === 1 && isValidPhone(val)) {
          this.getPhoneIsp();
          this.checkPhone();
        }
      },
      immediate: true,
    },
    isp(val) {
      if (val) {
        this.queryPhoneFaceStatus(val);
      } else {
        this.faceValueStatus = [];
      }
    },
    selectSku: {
      // 判断手机充值
      handler: function (val) {},
    },
    "selectSku.recharge_account_number": {
      handler: function (val) {
        this.phone_account =
          this.rechargeAccounts.find((item) => item.account_type_number === val)
            ?.account || "";
      },
      immediate: true,
    },
    "selectSku.instruction"() {
      this.$nextTick(() => {
        const height = document.querySelector(".rich-text")?.offsetHeight;
        if (height >= 300) {
          this.more = true;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.benefit-header {
  height: 223px;
}
.benefit-header-img {
  height: 100%;
}

.benefit-info {
  border-radius: 8px;
  overflow: hidden;
  margin: -73px 12px 0;
  position: relative;
  z-index: 1;
}

.benefit-btn {
  font-weight: bold;
  border: none;
  .btn-primary-style();
}

.benefit-btn-wrap {
  margin-top: 14px;
  padding: 0 22px;
}
.benefit-desc {
  margin: 15px 12px;
  border-radius: 8px;
  padding: 18px 16px;
  background: #fff;
  position: relative;
}
.benefit-desc-label {
  font-size: 13px;
  color: #333;
  line-height: 24px;
  margin-bottom: 10px;
}
.instruction-text {
  color: #666;
  line-height: 1.5;
}

.benefit-name {
  height: 70px;
  padding: 12px 10px 0 22px;
  box-sizing: border-box;
  background: url("../../assets/images/be-bg.gif") no-repeat center;
  position: relative;
  display: flex;
  margin-bottom: 6px;
}
.benefit-title {
  line-height: 20px;
  color: #6c3e0c;
  font-size: 14px;
  margin-bottom: 2px;
  position: relative;
  z-index: 10;
}
.benefit-amount {
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  height: 18px;
  background: linear-gradient(90deg, #feedd7 0%, #ffffff 100%);
  border-radius: 0px 9px 0px 6px;
  line-height: 18px;
  font-size: 11px;
  padding: 0 9px;
  color: #844f16;
}
.benefit-icon {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  margin-right: 11px;
}

.benefit-subtitle {
  line-height: 18px;
  color: #6c3e0c;
  font-size: 12px;
  opacity: 0.8;
  .font-family(PingFangSC-Light);
}

.benefit-sku {
  padding: 0 22px;
  border: 1px solid transparent;
}
.benefit-info-inner {
  background: #fff;
  border-radius: 10px;
  padding-bottom: 20px;
  overflow: hidden;
}
.benefit-info-title {
  font-size: 26px;
  color: #fff;
  line-height: 37px;
  padding-left: 22px;
  margin-bottom: 17px;
  // .font-family(PingFangSC-Ultralight);
}
.benefit-sku-input {
  display: flex;
  align-items: center;
  height: 41px;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 11px 13px;
  box-sizing: border-box;
  margin-top: 12px;
  font-size: 13px;
  position: relative;
  /deep/ .van-field__control {
    font-size: 13px;
  }
}

.benefit-input-label {
  flex-shrink: 0;
  padding: 0;
  .benefit-input-label-arrow {
    font-size: 20px;
    vertical-align: -5px;
  }
}

.benefit-input {
  flex: 1;
  padding: 0;
  font-size: 15px;
  background: transparent;
  position: relative;
  overflow: visible;
  &::after {
    content: none !important;
  }
}

.benefit-sku-list {
  margin-top: 12px;
  /deep/ .van-row {
    margin: -5px;
  }
  /deep/ .van-col--12 {
    .sku-item-inner-image {
      width: auto;
      height: auto;
    }
  }
}
.sku-item-inner {
  height: 44px;
  box-sizing: border-box;
  border: 0.5px solid #d2d2d2;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  &.new-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    &::after {
      content: none !important;
    }
    .sku-item-inner-image {
      width: 26px;
      overflow: hidden;
      border-radius: 50%;
      height: 26px;
      margin: 0 auto 3px;
      flex-shrink: 0;
    }
    .sku-item-name {
      font-size: 10px;
      text-align: center;
      padding: 0 1px;
      line-height: 16px;
      width: 100%;
      box-sizing: border-box;
      font-size: 11px;
      color: #333;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.sku-item-inner-image {
  height: 100%;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
}
.sku-item {
  padding: 5px;
  &.sku-flex {
    .sku-item-inner {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 0 8px;
      .sku-item-inner-image {
        margin: 0 8px 0 0;
        width: 24px;
        height: 24px;
      }
      .sku-item-name {
        width: auto;
        text-align: left;
      }
    }
  }
}
.sku-item.active {
  .sku-item-inner {
    border-color: #f6d9b8;
    box-sizing: border-box;
    background: #fff7ed;
    .out-stock {
      background-color: #f6d9b8;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 16px;
      height: 16px;
      background: url("../../assets/images/atour-selected.png") no-repeat;
      background-size: 100%;
    }
  }
}

.rich-text {
  color: #777;
  line-height: 22px;
  font-size: 11px;
  /deep/ img {
    max-width: 100%;
  }
  // &.rich-text-more {
  //   max-height: 300px;
  //   overflow: hidden;
  // }
  .instruction-html {
    margin-top: 24px;
  }
}
.more {
  margin: -24px auto 0;
  width: 80px;
  height: 24px;
  line-height: 24px;
  background: #ffc360;
  border-radius: 24px;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 100;
}

.to-order-btn {
  position: fixed;
  width: 36px;
  height: 40px;
  right: 0;
  top: 60%;
  z-index: 19;
  background: url("../../assets/images/benefit_order_bg.png") no-repeat
    center/100%;
}

.isp {
  position: absolute;
  right: 10px;
  top: 14px;
  color: #999999;
  font-size: 14px;
}

.to-share {
  position: absolute;
  width: 45px;
  height: 40px;
  right: 0;
  top: 24px;
  background: url("../../assets/images/share.png") no-repeat;
  background-size: 100%;
  z-index: 19;
}

.out-stock {
  font-size: 10px;
  position: absolute;
  right: -3px;
  top: -3px;
  background: #ccc;
  color: #fff;
  line-height: 12px;
  padding: 0 4px;
  border-radius: 5px 0px 5px 0px;
  z-index: 100;
}

.save-money {
  position: absolute;
  left: 10px;
  top: 15px;
  text-indent: 20px;
  background-color: rgba(255, 144, 0, 0.5);
  background-image: url("../../assets/images/logo.png");
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 16px;
  height: 26px;
  line-height: 26px;
  border-radius: 13px;
  padding: 0 12px 0 10px;
  font-size: 14px;
  color: #fff;
  z-index: 9;
}

.benefit-kaidivip_quarter {
  .benefit-amount {
    display: none;
  }
}

.new-benefit-style {
  .benefit-info {
    margin-top: -263px;
  }
  .benefit-header {
    height: 370px;
  }
}
.height0-enter-active,
.height0-leave-active {
  transition: all 0.5s;
  overflow: hidden;
}
.height0-enter,
.height0-leave-to {
  height: 0;
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
}
</style>