<template>
  <div
    class="wrap"
    :class="namespace"
    v-if="benefit_list.length"
    :style="{ backgroundImage: `url(${backgroundImg})` }"
  >
    <div class="head" :style="{ backgroundImage: `url(${headerImg})` }">
      <div class="content" :class="namespace">
        {{ namespace === "kkplus" ? "领取有效期至" : "会员有效期至" }}
        {{ card_info.card_expiry_date | formatDate("YYYY.MM.DD") }}
      </div>
    </div>
    <component
      :is="showComponent"
      :benefitList="benefit_list"
      :getRichText="getRichText"
    />
    <template v-if="showExtra">
      <WithExtraVue :benefitList="benefit_list" />
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { getUserBenefit } from "@/apis/home";
import { jsonSafeParse } from "@/utils";

import OneBenefit from "./OneBenefit.vue";
import TwoBenefit from "./TwoBenefit.vue";
import MoreBenefit from "./MoreBenefit.vue";
import WithExtraVue from "./WithExtra.vue";
import Empty from "./Empty.vue";

export default {
  components: {
    OneBenefit,
    TwoBenefit,
    MoreBenefit,
    WithExtraVue,
    Empty,
  },

  data() {
    return {
      showExtra: false,
      benefit_list: [],
      card_info: {
        current_cycle_expired_date: "",
        card_expiry_date: "",
        card_status: "expired",
      },
    };
  },
  computed: {
    ...mapState(["namespace", "userInfo"]),
    showComponent() {
      if (this.userInfo.card_status !== "active") return "Empty";
      if (this.namespace === "kkplus") return "OneBenefit";
      if (this.benefit_list.length === 2) return "TwoBenefit";
      if (this.benefit_list.length >= 3) return "MoreBenefit";
      return "OneBenefit";
    },
    headerImg() {
      const headerImg = jsonSafeParse(
        this.$getConfigValue("KUKAI_PAGE_HEADER")
      );
      if (headerImg[this.namespace]) return headerImg[this.namespace];
      return "https://common-h5-dev.xiangshuheika.com/static/upload/2024032058ff93e16449f.png";
    },
    backgroundImg() {
      const backgroundUrl = jsonSafeParse(
        this.$getConfigValue("COMMONPAGE_BGURL")
      );
      return backgroundUrl[this.namespace]
        ? backgroundUrl[this.namespace]
        : "https://common-h5-dev.xiangshuheika.com/static/upload/2024032043fc9272fe3b8.png";
    },
    computedWrapClass() {
      return this.namespace;
    },
  },

  methods: {
    async initData() {
      const loading = this.$loading();
      this.showExtra =
        this.$getConfigValue("COMMON_PAGE_EXTRA") &&
        this.userInfo.card_status === "active";
      try {
        const res = await getUserBenefit();
        // benefit_order：权益展示顺序
        // 根据权益展示顺序进行排序
        if (res.data.benefit_order) {
          const orders = res.data.benefit_order.split(",");
          res.data.benefit_list.sort((a, b) => {
            return orders.indexOf(a.type) - orders.indexOf(b.type);
          });
        }
        this.benefit_list = (res.data.benefit_list || []).filter(
          (r) => r.title
        );
        this.card_info = res.data;
      } finally {
        this.fetching = false;
        loading.clear();
      }
    },

    getRichText(title) {
      const RED_TITKL_TEXT_ENUM = jsonSafeParse(
        this.$getConfigValue("RED_TITKL_TEXT_ENUM") || "[]"
      );
      let result = title;
      RED_TITKL_TEXT_ENUM.forEach((item) => {
        if (title.indexOf(item) > -1) {
          result = title.replace(
            item,
            `<span style="color: #FF5001">${item}</span>`
          );
        }
      });
      return result;
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style scoped lang="less">
.wrap {
  min-height: 100vh;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: hidden;
  &.haixin,
  &.licheng7 {
    background-color: #ecf9ff;
  }
}
.head {
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 19px 22px -2px 22px;
  height: 85px;
  position: relative;
}
.content {
  position: absolute;
  top: 51px;
  left: 21px;

  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #ffd3bd;
  line-height: 17px;

  &.haixin,
  &.licheng7 {
    color: #ffffff;
  }
}
.card-past {
  text-align: center;
  height: 195px;
  background-color: #fff;
  border-radius: 5px;
  margin: 12px 12px 20px;
  overflow: hidden;
  p {
    text-align: center;
    line-height: 1.5;
    color: #404040;
    margin-top: 17px;
  }
  /deep/ .van-image {
    width: 80px;
    height: 80px;
    margin: 56px auto 14px;
  }
}
</style>
