<template>
  <FloorWrap :title="benefit.name" :desc="benefit.desc">
    <template slot="content">
      <img
        :src="benefit.display_img_url"
        class="img"
        @click="handleDraw"
        v-ans="{ event_id: 'unxp_video_click' }"
      />
    </template>
  </FloorWrap>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

import { useRouter } from "@/components/use/base";

import FloorWrap from "./FloorWrap.vue";

export default defineComponent({
  props: ["benefit"],

  components: {
    FloorWrap,
  },
  setup({ benefit }) {
    const router = useRouter();

    const handleDraw = () => {
      router.push(
        `/benefitDetail?benefit_type=${benefit.list[0].product_code}`
      );
    };

    return {
      handleDraw,
    };
  },
});
</script>


<style lang="less" scoped>
.img {
  margin-top: 13px;
  width: 100%;
  height: 160px;
  border: none;
}
</style>