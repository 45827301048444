<template>
  <FloorWrap :title="benefit.name" :desc="benefit.desc">
    <template slot="content">
      <div class="content-wrap">
        <div class="item" v-for="item in benefit.list" :key="item.desc">
          <img :src="item.display_img_url" alt="" @click="handleDraw(item)" />
          <div class="item-name">{{ item.desc }}</div>
          <div class="btn" v-if="item.benefit.amount === 0">本月已领取</div>
        </div>
      </div>
    </template>
  </FloorWrap>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

import { useRouter } from "@/components/use/base";

import FloorWrap from "./FloorWrap.vue";

export default defineComponent({
  components: {
    FloorWrap,
  },
  props: ["benefit"],

  setup() {
    const router = useRouter();
    const handleDraw = (item) => {
      router.push(`/benefitDetail?benefit_type=${item.product_code}`);
    };

    return {
      handleDraw,
    };
  },
});
</script>


<style lang="less" scoped>
.content-wrap {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .item {
    > img {
      width: 154px;
      height: 92px;
    }

    > .item-name {
      margin-top: 9px;
      font-weight: 400;
      font-size: 12px;
      color: #333333;
      line-height: 17px;
    }
    &:nth-child(3),
    &:nth-child(4) {
      margin-top: 14px;
    }
  }

  .btn {
    margin-top: 9px;
    background: #d9d9d9;
    border-radius: 14px;
    width: 88px;
    height: 28px;

    font-weight: 600;
    font-size: 13px;
    color: #ffffff;
    line-height: 28px;
    text-align: center;
  }
}
</style>