<template>
  <div>
    <van-empty
      van-empty
      description="商品已下架"
      :image="require('../../assets/images/activity/empty.png')"
      v-if="!sku"
    ></van-empty>
    <div class="mdetail" v-else-if="!loading">
      <div class="product-image">
        <van-image
          lazy-load
          class="product-display-image"
          :src="sku.image_url"
        />
        <div class="seckill">
          <div class="seckill-info">
            <div class="seckill-info-left">
              <div class="seckill-info-price">
                <div class="seckill-price"><small>¥</small>{{ sku.price }}</div>
                <div class="seckill-cost" v-if="savePrice > 0">
                  立省{{ savePrice }}元
                </div>
              </div>
              <div class="product-sales">
                <div :class="['product-progress', { empty: true }]">
                  <div
                    class="product-progress-bar"
                    :style="`width: calc(${100 - getWidth}% - 2px)`"
                  ></div>
                </div>
                <div class="product-sales-text">剩余{{ 100 - getWidth }}%</div>
              </div>
            </div>

            <div class="seckill-desc" v-if="activity.status !== 'end'">
              <div class="seckill-count-down">
                <div class="seckill-count-text">
                  {{ activityEnum[activity.status].detailText }}
                </div>
                <van-count-down
                  class="count-down"
                  @finish="finish"
                  :time="getCountTime"
                >
                  <template #default="timeData">
                    <template v-if="timeData.days > 0">
                      <div class="block day">{{ timeData.days }}天</div>
                      <div class="colon">:</div>
                    </template>
                    <div class="block">
                      {{ timeData.hours | zero }}
                    </div>
                    <div class="colon">:</div>
                    <div class="block">{{ timeData.minutes | zero }}</div>
                    <div class="colon">:</div>
                    <div class="block">{{ timeData.seconds | zero }}</div>
                  </template>
                </van-count-down>
              </div>
            </div>
            <div v-else class="activity-unstart">
              {{ activityEnum[activity.status].detailText }}
            </div>
          </div>
        </div>
      </div>

      <div class="card-style">
        <div class="sku-name">
          {{ fullName }}
        </div>
        <div class="face_value">原价: ¥{{ sku.face_value }}</div>
      </div>
      <div class="sku-info card-style" v-if="showAccount || columns">
        <main class="benefit-body">
          <div v-if="showAccount" class="benefit-body-cell">
            <div
              class="input-label"
              @click="showSelectRechargePopup = true"
              :style="`pointer-events: ${
                sku.allowed_recharge_account_types.length > 1
                  ? 'initial'
                  : 'none'
              }`"
            >
              充值{{ topUpText
              }}<svg-icon
                v-if="sku.allowed_recharge_account_types.length > 1"
                class="benefit-input-label-arrow"
                iconClass="arrow"
              />
            </div>
            <van-field
              class="input-control"
              :placeholder="placeholder"
              :value="account"
              :disabled="disabled"
              @input="changeValue"
            />
          </div>
          <div v-if="columns" class="benefit-body-cell game-cell">
            <div class="input-label">游戏大区</div>
            <div class="game-area-select" @click="showPicker = true">
              {{ columns[currentGameIndex] }}
            </div>
            <van-popup v-model="showPicker" round position="bottom">
              <van-picker
                show-toolbar
                :columns="columns"
                title="请选择游戏区"
                item-height="34"
                @cancel="showPicker = false"
                @confirm="changeGame"
              />
            </van-popup>
          </div>
        </main>
      </div>
      <div class="benefit-body-cell card-style">
        <div class="input-label">权益介绍与说明</div>
        <div class="instruction">
          <div
            v-if="sku.instruction || detail.desc"
            class="instruction-text"
            v-html="sku.instruction || detail.desc"
            v-replace.day="user_coupon_day.coupon_days"
          ></div>
        </div>
      </div>
      <div class="height60">
        <div class="product-submit">
          <div v-if="!getDisabled && savePrice > 0" class="discount">
            立省{{ savePrice }}元
          </div>
          <van-button
            v-if="getDisabled"
            disabled
            size="large"
            block
            type="info"
          >
            {{ getBtnText }}
          </van-button>
          <van-button
            v-else
            @click="beforeSubmit"
            v-ans="{
              event_id: 'sekill_benefit_click',
              sku_id: sku.id,
              sku_name: sku.name,
            }"
            size="large"
            block
            type="info"
            >立即抢购
          </van-button>
        </div>
      </div>
    </div>
    <selectPopup
      title="选择账号充值类型"
      :value="sku.recharge_account_number"
      @update:value="changeRechargeAccountNumber"
      :options="sku.allowed_recharge_account_types"
      :optionFieldMap="{
        label: 'account_type_name',
        value: 'account_type_number',
      }"
      v-model="showSelectRechargePopup"
    />
  </div>
</template>

<script>
import qs from "qs";

import {
  activateBenefit,
  getItemInfo,
  getOrderList,
  getSeckillInfo,
  getSpuDetail,
  updateOrderStatus,
  updateUserPhone,
  getUserBenefitCouponDays,
  querySeckillStock,
  activitySkillOrder,
  getSeckillResult,
  cancelSeckillRecord,
  querySeckillRecords,
} from "@/apis/home";
import dayjs from "dayjs";
import { ACTIVITY_ENUM } from "@/constants";
import { mapState } from "vuex";
import { getStrFromJsonStr, replaceDay, storage } from "@/utils";
import ans from "@/utils/ans";
import { Decimal } from "decimal.js";
import debounce from "lodash.debounce";
import { getOrder } from "@/apis";
import { ENV_FLAG, getBaseUrl, IS_PROD } from "@/config";
import GeeTest from "@/utils/geetestVerify";
import selectPopup from "@/components/selectPopup";
import { queryUserRechargeAccount } from "@/apis/product";

export default {
  components: {
    selectPopup,
  },
  data() {
    return {
      detail: {},
      sku: {
        allowed_recharge_account_types: [],
      },
      account: "",
      showPicker: false,
      currentGameIndex: 0,
      loading: true,
      seckillStatus: 0,
      activity: { stock: {} },
      activityEnum: ACTIVITY_ENUM,
      user_coupon_day: {},
      counter: 0,
      showSelectRechargePopup: false,
      rechargeAccounts: [],
    };
  },
  computed: {
    ...mapState(["return_url", "userInfo", "namespaceInfo", "namespace"]),
    savePrice() {
      const d_price = new Decimal(this.sku.face_value || 0);
      return d_price.sub(new Decimal(this.sku.price || 0)).toNumber();
    },
    topUpText() {
      return (
        this.sku.allowed_recharge_account_types?.find(
          (item) =>
            item.account_type_number === this.sku.recharge_account_number
        )?.account_type_name || "充值账号"
      );
    },
    getDisabled() {
      return (
        this.activity.status !== "start" ||
        this.getWidth === 100 ||
        this.activity.stock?.user_daily_stock === 0
      );
    },
    getBtnText() {
      if (this.activity.stock?.user_daily_stock === 0) {
        return "已抢购";
      }
      if (this.getWidth === 100) {
        return "售罄";
      }
      return this.activityEnum[this.activity.status].btnName;
    },
    // 账号充值类型为手机号的sku，自动填入权益手机号
    disabled() {
      // 未启用充值账号绑定功能，不禁用
      if (!this.namespaceInfo.enable_recharge_account_limit) {
        return false;
      }
      return !!this.rechargeAccounts.find(
        (item) => item.account_type_number === this.sku.recharge_account_number
      );
    },
    user_phone() {
      if (this.sku.recharge_account_number !== 1) {
        return "";
      }
      return (
        this.rechargeAccounts.find(
          (item) =>
            item.account_type_number === this.sku.recharge_account_number
        )?.account || ""
      );
    },
    getWidth() {
      const { stock } = this.activity;
      return Number(
        ((stock.session_used_stocks / stock.session_stocks) * 100).toFixed(0)
      );
    },
    // 获取倒计时
    getCountTime() {
      let time;
      if (this.activity.status === "start") {
        time = this.activity.end_time;
      } else {
        time = this.activity.start_time;
      }
      const cur = dayjs().valueOf();
      return dayjs(time).valueOf() - cur;
    },
    columns() {
      const info = this.sku.special_info;
      if (!info) {
        return null;
      }
      try {
        const json = JSON.parse(info);
        return json.GameTempaltePreviewList[0].GameList.ChargeRegion.map(
          (r) => r.Name
        );
      } catch (e) {
        return null;
      }
    },

    showAccount() {
      return this.sku.allowed_recharge_account_types?.length;
    },
    placeholder() {
      return "请输入" + this.topUpText;
    },
    fullName() {
      return this.sku.name;
    },
  },
  async mounted() {
    const loading = this.$loading();
    try {
      const { item_code, activityName } = this.$route.query;
      const [res, activityRes, rechargeAccountRes] = await Promise.all([
        getItemInfo({ item_code }),
        getSeckillInfo({
          activity_name: activityName,
        }),
        queryUserRechargeAccount(),
      ]);
      const sku = await getSpuDetail({
        id: res.product,
        namespace: this.namespace,
      });
      this.rechargeAccounts = rechargeAccountRes.data;

      const user_coupon_day = await getUserBenefitCouponDays({
        product_code: res.product_code,
        card_type: this.userInfo.card_type,
      });
      const { session_id } = this.$route.query;
      let activity = activityRes.data.session_info.find(
        (item) => item.start_time === Number(session_id)
      );
      if (!activity) {
        activity = { status: "end" };
      } else {
        activity.status = this.getStatus(
          activity.start_time,
          activity.end_time
        );
      }
      this.activity = activity;
      this.detail = {
        ...sku.data,
        skuTypeName: sku.data.type_of_purchase[0],
      };
      const item = sku.data.skus.find((item) => item.item_code === item_code);
      if (!item) {
        return;
      }

      if (
        item.allowed_recharge_account_types &&
        item.allowed_recharge_account_types.length > 0
      ) {
        item.recharge_account_number =
          item.allowed_recharge_account_types[0].account_type_number;
      }
      this.sku = item;

      this.user_coupon_day = user_coupon_day.data;
      ans.pageView(this.$route.meta.page, {
        namespace: this.namespace,
        sku_id: this.sku.id,
        sku_name: this.sku.name,
      });
      await this.queryStock();

      // 未开始和已开始的活动，轮询状态
      if (activity.status !== "end") {
        this.timer = setInterval(() => {
          this.changeStatus();
        }, 1000);
      }
    } finally {
      this.loading = false;
      loading.clear();
    }
  },
  methods: {
    async beforeSubmit() {
      this.validatePay();
    },
    // 下单前校验
    changeRechargeAccountNumber(type) {
      this.sku = {
        ...this.sku,
        recharge_account_number: type,
      };
    },
    validatePay: debounce(
      async function fn() {
        // 账号限制
        if (this.showAccount && !this.account) {
          this.$toast("请输入充值账号");
          return;
        }

        // 校验输入的账号格式
        const rechargeConfig = this.sku.allowed_recharge_account_types.find(
          (item) =>
            item.account_type_number === this.sku.recharge_account_number
        );
        if (rechargeConfig?.account_regex_rule) {
          if (
            !new RegExp(rechargeConfig?.account_regex_rule).test(this.account)
          ) {
            this.$toast(`请输入正确的${rechargeConfig.account_type_name}`);
            return;
          }
        }

        let token = storage.getItem("token");

        if (!token) {
          this.$toast("您还未登录，请先登录~");
          return;
        }
        if (!this.userInfo.is_member) {
          const un_index = this.$getConfigValue("UN_INDEX");
          if (un_index) {
            this.$confirm({
              message: "您还不是会员，开通会员即可享受特惠权益",
              cancelButtonText: "我再想想",
              confirmButtonText: "去开通",
            }).then(() => {
              location.href = un_index;
            });
          } else {
            this.$toast("本活动仅限VIP会员参加，请先开通会员~");
          }
          return;
        }

        // 判断是否新用户专享
        if (
          !this.userInfo.user_tags?.new_user &&
          this.$route.query.activityName.includes("_seckill_new_user")
        ) {
          this.$alert({
            message: "您已享受过新人优惠，看看其他吧",
          });
          return;
        }
        const { activityName, session_id } = this.$route.query;

        // 查询用户是否有未支付的秒杀订单
        const records = await querySeckillRecords({
          activity_name: activityName,
          session_id,
        });
        if (records.data.length) {
          this.$confirm({
            message: this.$route.query.activityName.includes(
              "_seckill_new_user"
            )
              ? "新人专享商品每位新用户可抢购1次。正在抢购，请选择"
              : "单个商品仅可抢购一次，您有一笔订单正在抢购，请选择",
            cancelButtonText: "取消订单",
            confirmButtonText: "继续支付",
          })
            .then(async () => {
              let token = storage.getItem("token");
              const res = await getOrder({
                order_number: records.data[0].order_number,
              });
              const order = res.data.res_list[0];
              const orderInfo = JSON.parse(order.pay_info);
              const params = {
                item_code: orderInfo.item_code,
                price: orderInfo.item_price,
                item_name: decodeURIComponent(order.name),
                sku_name: decodeURIComponent(order.name),
                name: decodeURIComponent(order.name),
                detail_type: order.name,
                type: order.type,
                phone_number: orderInfo.phone_number,
                activityName,
                session_id,
                user_token: token,
                order_number: records.data[0].order_number,
              };
              this.$router.push(
                `/pages/pay/pay?${qs.stringify(params, { encode: false })}`
              );
            })
            .catch(async () => {
              await cancelSeckillRecord({
                status: "canceled",
                session_id,
                order_number: records.data[0].order_number,
                activity_name: activityName,
              });
              this.$toast("取消成功");
            });
          return;
        }

        // 查询库存
        const { item_code } = this.$route.query;
        const stock = await querySeckillStock({
          item_code,
          session_id: this.activity.start_time,
          activity_name: activityName,
          platform: "extra",
        });
        if (stock.data.session_used_stocks >= stock.data.session_stocks) {
          this.$toast("库存不足，请稍后再试");
          return;
        }
        if (this.sku.top_up_type === 3 || this.sku.top_up_type === 4) {
          await this.$confirm({
            message: replaceDay(
              this.sku.activation_tips || `购买后{day}天内使用有效，请确定`,
              this.user_coupon_day.coupon_days || 3
            ),
          });
        }
        if (
          !this.disabled &&
          this.sku.recharge_account_number === 1 &&
          this.namespaceInfo.enable_recharge_account_limit
        ) {
          const loading = this.$loading();
          try {
            await updateUserPhone({
              user_phone: this.account,
              hideMsg: true,
            });
          } catch (e) {
            this.$toast("手机号已被其它账号绑定，不可使用，如有疑问请联系客服");
            return;
          } finally {
            loading.clear();
          }
        }
        this.toPay();
      },
      1500,
      { leading: true, trailing: false }
    ),

    changeStatus() {
      this.activity.status = this.getStatus(
        this.activity.start_time,
        this.activity.end_time
      );
    },
    getStatus(start_time, end_time) {
      let status;
      if (dayjs().isBefore(dayjs(start_time))) {
        status = "unstart";
      } else if (dayjs().isAfter(dayjs(end_time))) {
        status = "end";
      } else {
        status = "start";
      }
      return status;
    },
    // 查询库存
    async queryStock() {
      const { item_code } = this.$route.query;
      if (!item_code) {
        return;
      }
      const res = await querySeckillStock({
        item_code,
        session_id: this.activity.start_time,
        activity_name: this.$route.query.activityName,
      });
      this.activity.stock = res.data[item_code];
      this.activity = { ...this.activity };
      if (this.activity.status === "start") {
        this.stockTimer = setTimeout(() => {
          clearTimeout(this.stockTimer);
          this.queryStock();
        }, 4000);
      }
    },
    async toPay() {
      // top_up_type:
      // (1, "直冲-购买"),
      // (2, "直冲-免费领取"),
      // (3, "兑换码-购买"),
      // (4, "兑换码-免费领取"),
      // (5, "跳转H5")

      const { activityName, session_id, item_code } = this.$route.query;
      // 跳转收银台
      const record = await activitySkillOrder({
        item_code,
        activity_name: activityName,
        session_id,
        phone_number: this.account,
        benefit_source: this.benefit_source || "会员权益",
        order_source: this.userInfo.card_type_cn,
        enable_auto_jump: 1,
        partner_jump_url: encodeURIComponent(
          (window.location.origin + window.location.pathname).replace(
            /\/$/,
            ""
          ) + `/pages/pay_result/pay_result?category_type=benefit`
        ),
        partner_url: encodeURIComponent(
          (window.location.origin + window.location.pathname).replace(
            /\/$/,
            ""
          ) + `/pages/pay_result/pay_result?category_type=benefit`
        ),
        envflag: ENV_FLAG(this.namespace),
        is_prod: IS_PROD,
        namespace: this.namespace,
        timestamp: new Date().getTime(),
        project_url: getBaseUrl(this.namespace),
        out_order_id: `OAK${navigator.productSub}${new Date().getTime()}`,
      });

      this.querySeckillResult(record.data);
    },

    // 轮训秒杀结果
    async querySeckillResult(record_id) {
      const loading = this.$loading("正在抢购中");
      this.seckilling = setInterval(async () => {
        this.counter++;
        try {
          const res = await getSeckillResult({ record_id });
          if (res.code === 200) {
            this.counter = 0;
            clearInterval(this.seckilling);
            loading.clear();
            const { activityName, session_id } = this.$route.query;
            // 跳转收银台
            let token = storage.getItem("token");
            const params = {
              order_number: res.data,
              item_code: this.sku.item_code,
              price: this.sku.price,
              item_name: decodeURIComponent(this.fullName),
              sku_name: decodeURIComponent(this.fullName),
              name: decodeURIComponent(this.fullName),
              detail_type: this.fullName,
              id: this.detail.id,
              type: this.detail.product_code,
              phone_number: this.account,
              activityName,
              session_id,
              user_token: token,
              recharge_account_type: this.sku.recharge_account_number || 0,
            };
            this.$router.push(
              `/pages/pay/pay?${qs.stringify(params, { encode: false })}`
            );
          } else if (this.counter >= 20) {
            throw new Error("未抢到");
          }
        } catch (e) {
          this.counter = 0;
          loading.clear();
          clearInterval(this.seckilling);
          this.$toast("抢购失败，请稍后再试");
        }
      }, 1100);
    },
    finish() {},
    changeGame(index) {
      this.currentGameIndex = index;
      this.showPicker = false;
    },
    changeValue(e) {
      this.account = e;
    },
  },
  watch: {
    "sku.recharge_account_number": {
      handler: function (val) {
        this.account =
          this.rechargeAccounts.find((item) => item.account_type_number === val)
            ?.account || "";
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.seckilling);
    clearTimeout(this.stockTimer);
  },
};
</script>

<style lang="less" scoped>
.product-image {
  height: 224px;
  position: relative;
  overflow: hidden;
  & + .card-style {
    border-radius: 0 0 10px 10px;
  }
}
.product-display-image {
  height: 100%;
  position: relative;
  /deep/ img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.seckill {
  padding-top: 12px;
  height: 64px;
  background: url("../../assets/images/activity/flash_sale_bg.png") no-repeat;
  background-size: 100%;
  box-sizing: border-box;
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 0;
}
.seckill-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  color: #fff;
}
.seckill-image {
  height: 20px;
  /deep/ img {
    width: auto;
  }
}

.product-progress {
  width: 70px;
  border-radius: 3px;
  height: 5px;
  box-sizing: border-box;
  position: relative;
  margin-right: 6px;
  background-color: #fff;
  &.empty {
    border-color: #999;
  }
}
.face_value {
  line-height: 17px;
  color: #999;
}
.product-progress-bar {
  position: absolute;
  left: 1px;
  top: 1px;
  height: 3px;
  border-radius: 3px;
  background-color: #ff5001;
  z-index: 100;
  transition: all 0.3s;
}

.seckill-count-down {
  margin-bottom: 3px;
  font-size: 12px;
}

.product-sales {
  display: flex;
  align-items: center;
}
.seckill-desc {
  padding-right: 5px;
}
.seckill-count-text {
  text-align: center;
  margin-bottom: 6px;
  color: #ffd092;
}
.count-down {
  display: flex;
  margin-left: 5px;
  color: #ffd092;
}
.colon {
  padding: 0 2px;
}
.block {
  width: 17px;
  height: 17px;
  background-color: #ffd092;
  text-align: center;
  line-height: 17px;
  color: #222;
  font-size: 12px;
  border-radius: 1px;
  &.day {
    width: auto;
    padding: 0 2px;
  }
}

.sku-info {
  margin-top: -17px;
}

.card-style {
  background-color: #fff;
  border-radius: 8px;
  margin: 0 10px 10px;
  padding: 15px 10px;
  box-shadow: 0px 0px 5px 0px rgba(153, 153, 153, 0.1);
}

.benefit-body-cell {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
.input-control {
  padding: 9px 8px;
  font-size: 16px;
  background-color: #f8f8f8;
  border-radius: 4px;
}
.input-label {
  font-weight: bold;
  line-height: 20px;
  font-size: @font-md;
  margin-bottom: 10px;

  .benefit-input-label-arrow {
    font-size: 20px;
    vertical-align: -5px;
  }
}
.game-cell {
  margin-bottom: 30px;
}
.product-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px;
}
.game-area-select {
  width: 180px;
  height: 34px;
  border: 1px solid @border-color;
  position: relative;
  border-radius: 4px;
  line-height: 34px;
  padding: 0 6px;
  overflow: hidden;
  box-sizing: border-box;
  &::after {
    content: "";
    position: absolute;
    right: 6px;
    top: 13px;
    width: 0;
    height: 0;
    border: 5px solid @text-color;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
  }
}
.sku-name {
  line-height: 28px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 2px;
}

.instruction-text {
  color: @text-gray2-color;
  line-height: 22px;
  white-space: pre-wrap;
  /deep/ img {
    max-width: 100%;
    height: auto;
  }
}

.product-submit {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
}
.discount {
  position: absolute;
  background: linear-gradient(90deg, #ff8743 0%, #ff1d43 100%);
  border-radius: 12px 12px 12px 0;
  padding: 0 0.7px;
  color: #ffe9cd;
  right: 15px;
  top: -7px;
  height: 24px;
  line-height: 24px;
  z-index: 1;
  padding: 0 6px;
  font-size: @font-xs;
}
.height60 {
  height: 60px;
}
.activity-unstart {
  line-height: 35px;
  font-size: 16px;
  padding-right: 10px;
}

/deep/ .van-field__control {
  font-size: 14px;
}
.seckill-info-price {
  display: flex;
  align-items: center;
}
.seckill-price {
  font-size: 24px;
  font-weight: bold;
  small {
    font-size: 0.6em;
    padding-right: 2px;
  }
}
.seckill-cost {
  background: url("../../assets/images/activity/rectangle.png") no-repeat;
  background-size: 100%;
  height: 16px;
  line-height: 16px;
  font-size: 10px;
  color: #ffe9cd;
  padding: 0 2px;
  margin-left: 10px;
}
</style>