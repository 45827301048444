<template>
  <FloorWrap :title="benefit.name" :desc="benefit.desc">
    <template slot="content">
      <div class="content" ref="contentRef">
        <img
          :src="benefit.display_img_url"
          class="img"
          @load="handleImageLoad"
          v-ans="{ event_id: eventId }"
          @click="handleDraw"
        />
      </div>
    </template>
  </FloorWrap>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onUnmounted,
} from "@vue/composition-api";

import { useRouter } from "@/components/use/base";

import FloorWrap from "./FloorWrap.vue";

export default defineComponent({
  components: {
    FloorWrap,
  },
  props: ["benefit"],
  setup({ benefit }) {
    const router = useRouter();

    const animationState = ref("stop");
    const contentRef = ref(null);
    const scrollWidth = ref();
    const timeOut = ref(null);

    const eventId = computed(() => {
      return benefit.name === "热门电视剧"
        ? "unxp_dramas_click"
        : "unxp_show_click";
    });

    const handleDraw = () => {
      router.push(
        `/benefitDetail?benefit_type=${benefit.list[0].product_code}`
      );
    };

    const handleAnimation = () => {
      if (animationState.value === "back") {
        contentRef.value.scrollLeft = contentRef.value.scrollLeft - 5;
        if (contentRef.value.scrollLeft <= 1) {
          animationState.value = "stop";
          return;
        }
      }

      if (animationState.value === "start") {
        let offset = contentRef.value.scrollLeft;
        contentRef.value.scrollLeft = offset + 5;
        if (contentRef.value.scrollLeft >= scrollWidth.value) {
          animationState.value = "stop";
          return;
        }
      }
      window.requestAnimationFrame(handleAnimation);
    };

    const handleImageLoad = () => {
      scrollWidth.value =
        contentRef.value.scrollWidth - contentRef.value.clientWidth;
      timeOut.value = setInterval(() => {
        if (contentRef.value.scrollLeft < scrollWidth.value)
          animationState.value = "start";
        if (contentRef.value.scrollLeft >= scrollWidth.value)
          animationState.value = "back";
        window.requestAnimationFrame(handleAnimation);
      }, 3000);
    };

    onUnmounted(() => {
      if (timeOut.value) clearInterval(timeOut.value);
    });

    return {
      contentRef,
      handleImageLoad,
      handleDraw,
      eventId,
    };
  },
});
</script>

<style lang="less" scoped>
.content {
  margin-top: 14px;
  overflow: scroll;
  position: relative;
  width: 100%;
  height: 200px;
  &::-webkit-scrollbar {
    display: none;
  }

  .img {
    height: 184.51px;
  }
}
</style>
